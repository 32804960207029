/**
 * About us FR meta
 */
const meta = {
  keywords: "Transfert d'argent, cameroun sans frais",
  meta_description_default:
    "Transférez de l'argent GRATUITEMENT et instantanément à vos proches en Afrique avec TRANSFERGRATIS, la solution rapide et sécurisée, disponible à tout moment",
  meta_description_register:
    "Inscrivez-vous avec Transfergratis et profitez de transferts d’argent internationaux instantanés et gratuits. Rejoignez-nous aujourd’hui pour une expérience transparente!",
  meta_description_login:
    "Connectez-vous à votre compte Transfergratis pour des transferts d’argent internationaux sécurisés et instantanés. Accès rapide, gratuit et facile!",
  meta_description_secure:
    "Transfergratis est réglementé et autorisé à effectuer les transferts d'argent par le gouvernement fédéral canadien et le gouvernement provincial du Québec.",
  meta_description_faqs:
    "Trouvez des réponses aux questions les plus courantes sur Transfergratis. Consultez notre FAQ pour obtenir des détails sur les transferts d’argent internationaux, la sécurité et plus encore.",
  meta_description_contact_us:
    "Contactez Transfergratis pour toute demande ou assistance. Nous sommes là pour vous aider avec des transferts d’argent internationaux sécurisés et rapides.",
  meta_description_terms: "Termes et conditions de Transfergratis",
  meta_description_privacy:
    "Cette politique de confidentialité décrit la façon dont Transfergratis utilise, stocke, gère et dispose de certaines informations que vous, en tant qu'utilisateur de nos services, nous fournissez.",
  meta_description_send:
    "Avec Transfergratis, tranfert de l’argent GRATUITEMENT à tes proches au Cameroun en quelques minutes.",
  meta_description_cookies:
    "Cette politique de confidentialité décrit la façon dont Transfergratis utilise, stocke, gère et dispose de certaines informations que vous, en tant qu'utilisateur de nos services, nous fournissez.",

  meta_description_transfer:
    "Avec Transfergratis, tranfert de l’argent GRATUITEMENT à tes proches au {{country}} en quelques minutes.",
  titlesecure: "La sécurité chez Transfergratis",

  titleterms: "Termes et conditions | Transfergratis",
  titleprivacy: "Politique de confidentialité | Transfergratis",

  title: {
    about_us: "A propos de nous",
    cid: "KYC",
    contact_us:
      "Contactez Transfergratis pour toute demande ou assistance. Nous sommes là pour vous aider avec des transferts d’argent internationaux sécurisés et rapides.",
    dashboard: {
      profile: "Profil",
      security: "Securité",
      transaction: "Mes transactions",
    },
    default: "Transfergratis - Transfert d'argent gratuit et instantané",
    faqs: "Foire aux questions",
    login:
      "Se connecter : Connectez-vous à votre compte Transfergratis et envoyez de l'argent vers le Cameroun et autres pays Sans frais. Rapide et sécurisé!",
    register:
      "S'inscrire : Créez votre compte dès maintenant pour commencer. Rapide, Gratuit, Sécurisé!",
    resetPassword: "Réinitialiser le mot de passe",
    transfer: "Envoyer de l’argent",
    career: "Carrière : Nous recherchons un nouveau challenger",
    terms: "Conditions et conditions d'utilisation",
    cookies: "Cookies  ",
    policy: "Politique de confidentialité ",
    transferTitle: "Envoyer de l’argent",
  },
};

export default meta;
