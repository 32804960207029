/**
 * Transaction FR translations
 */
const transaction = {
  transactions: "Transactions",
  profile: "Profil",
  security: "Securité",
  send_via: "Envoyer via",
  showing: "Montrant",

  amount_transferred: "Montant transféré",
  tip_amount: "Montant du Pourboire",
  beneficiary: "Bénéficiaire",
  changePasswordError: "Mot de pass actuel incorrect.",
  changePasswordSuccessful: "Mot de pass modifié.",
  corfirmationNewPassword: "Confirmation Nouveau mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  costs: "Frais",
  date: "Date",
  newPassword: "Nouveau mot de passe",
  newPasswordDifferent:
    "Le nouveau mot de passe doit etre different de l'ancien",
  oldPassword: "Ancien mot de passe",
  personal: "Personal details",
  shipper: "Expéditeur",
  total_amount: "Montant total",
  wallet: "Portefeuille",
  bank: "Banque",
  invite: "invite",
  currentRate: "Taux actuel",
};

export default transaction;
