const title = {
  heroTitle:
    "Transfergratis, Le moyen sûr, facile et rapide de transférer de l'argent {{article}} {{country}}",
  intro: {
    download: "Téléchargez Transfergratis maintenant !",
  },

  service: {
    title: "Avantages de notre service d'envoi d'argent",
    subTitle:
      "Des transferts de fonds simples, rapides et sécurisés à des taux les plus bas",
    cards1: {
      title: "Facile à utiliser",
      description:
        "Transfergratis est conçu pour faciliter le transfert d'argent à l'international en quelques clics depuis votre téléphone mobile, votre tablette ou votre ordinateur, avec des frais réduits et des taux de change compétitifs.",
    },
    cards2: {
      title: "Paiements rapides",
      description:
        "Le système Transfergratis est conçu et optimisé pour faciliter le transfert d'argent à l'international de manière rapide. Du dollar canadien vers le FCFA, vers le Dinar ou encore vers le Rand.",
    },
    cards3: {
      title: "Gratuit",
      description:
        "Le service Transfergratis est totalement gratuit pour les transferts d'argent à l'international. Seul le montant exact que vous sounaltez transterer sera tacture, sans trais supplémentaires.",
    },
    cards4: {
      title: "Sécurisé",
      description:
        "Transfergratis est agréé en tant qu'entreprise de services monétaires, disposant des autorisations nécessaires pour les transferts d'argent à l'international avec des émissions en dollars canadiens (CAD) reconnues par le gouvernement canadien.",
    },
  },

  usage: {
    title: "C'EST DE LA TARTE",
    subTitle:
      "Rejoignez les milliers d’utilisateurs de notre service de transfert d'argent a l'international qui nous font confiance, pour leurs transactions financières, pour la rapidité des transferts de fonds et pour la sécurité que représente TRANSFERGRATIS.",
    cards1: {
      title: "Inscription",
      description:
        "Inscrivez-vous sur Transfergratis et commencez à envoyer des fonds en quelques minutes ! Créez votre compte maintenant pour commencer. Rapide, gratuit, sécurisé !",
    },
    cards2: {
      title: "Envoyer de l'argent",
      description:
        "Le destinataire reçoit des fonds avec Transfergratis gratuitement du Canada au Cameroun dans quelques minutes.",
    },
    cards3: {
      title: "Le destinataire reçoit des fonds",
      description:
        "Le destinataire reçoit des fonds avec Transfergratis gratuitement du Canada au Cameroun dans quelques minutes.",
    },
  },
  testimony: {
    title: "Ce que disent nos clients",
    subTitle:
      "Découvrez les témoignages de ceux qui ont choisi notre service pour des transferts d'argent rapides, sécurisés et sans tracas. Leur satisfaction est notre priorité !",
  },
  blog: {
    title: "Dernières nouvelles",
    subTitle:
      "Restez informé sur l'actualité financière, les mises à jour et les meilleures astuces dont vous avez besoin.",
  },
};
export default title;
