const title = {
  heroTitle:
    "Transfergratis, The safe, easy and Fast way to Transfer money with No fees {{article}} {{country}}",
  intro: {
    download: "Download Transfergratis now !",
  },

  service: {
    title: "Benefits of our money transfer service",
    subTitle: "Simple, fast and secure money transfers at the lowest rates",
    cards1: {
      title: "Easy to use",
      description:
        "Transfergratis is designed to make it easy to transfer money internationally in just a few clicks from your mobile phone, tablet or computer, with reduced fees and competitive exchange rates.",
    },
    cards2: {
      title: "Fast payments",
      description:
        "The Transfergratis system is designed and optimized to facilitate the transfer of money internationally in a fast way. From the Canadian dollar to the FCFA, to the Dinar or even to the Rand.",
    },
    cards3: {
      title: "Free of charge",
      description:
        "The Transfergratis service is completely free for international money transfers. Only the exact amount you are willing to transfer will be taken, without any additional fees.",
    },
    cards4: {
      title: "100% secure",
      description:
        "Transfergratis is licensed as a money service company, with the necessary authorizations for international money transfers with Canadian dollar (CAD) issues recognized by the Canadian government.",
    },
  },
  usage: {
    title: "EASY AS 1.2.3",
    subTitle:
      "Join the thousands of users of our international money transfer service who trust us for their financial transactions, for the speed of funds transfers and for the security that TRANSFERGRATIS represents.",
    cards1: {
      title: "Register",
      description:
        "Sign up with Transfergratis and start sending money online in minutes•! Create your account now to get started. Fast, Free, Secure!",
    },
    cards2: {
      title: "Send money",
      description:
        "The recipient receives funds with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
    },
    cards3: {
      title: "The recipient receives funds",
      description:
        "The recipient receives funds with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
    },
  },
  testimony: {
    title: "What our customers are saying",
    subTitle:
      "Discover the testimonials of those who have chosen our service for fast, secure, and hassle-free money transfers. Their satisfaction is our top priority!",
  },
  blog: {
    title: "Latest news",
    subTitle:
      "Stay up to date on financial news, updates and the best tips you need.",
  },
};
export default title;
